import { BrowserRouter, Routes, Route } from 'react-router-dom'

// pages & components
import Home from './pages/Home'
import Services from './pages/Services'
import Experience from './pages/Experience'
import Employment from './pages/Employment.js'
import ContactUs from './pages/ContactUs';
import Navbar from './components/Navbar'

function App() {

  return (
    <div className="App">
      <BrowserRouter>
        <Navbar />
        <div className="pages">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/services" element={<Services />} />
            <Route path="/experience" element={<Experience />} />
            <Route path="/employment" element={<Employment />} />
            <Route path="/contactus" element={<ContactUs />} />
          </Routes>
        </div>
      </BrowserRouter>
    </div>
  );
}

export default App;