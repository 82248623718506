import ContactUsForm from '../components/ContactUsForm';

const ContactUs = () => {
  return (
    <div>
      <h1>Contact Us Page</h1>
      <ContactUsForm />
    </div>
  );
}

export default ContactUs;
