import React from 'react';
import './Home.css';
import image from '../assets/homeicon.png'; // Adjust the path if necessary

const Home = () => {
    return (
        <div className="home">
            <div className="home-content">
                <h1 className="home-title">Empowering Your IT Solutions</h1>
                <p className="home-description">
                    BINZSOLUTIONS is a Maryland based IT Consulting Company. We specialize in program/project management, software application development, data management, and staff augmentation services. Our expertise in bringing best practices to IT solutions is particularly valuable in the healthcare domain. Let's discuss how we can help you achieve your business objectives.
                </p>
            </div>
            <div className="home-image">
                <img src={image} alt="IT Solutions" />
            </div>
        </div>
    );
}

export default Home;
