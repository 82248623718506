import './Services.css';

const Services = () => {
    return (
        <div className="services">
            <h2>Our Comprehensive Services</h2>
            <p>
                At BINZSOLUTIONS, we offer a wide range of comprehensive IT services tailored to your specific requirements. Our services include:
            </p>
            <div className="service-list">
                <div className="service-item">
                    <h3>Project Management</h3>
                    <p>
                        Our expert project management services ensure the successful initiation, planning, execution, monitoring, and closure of your IT projects.
                    </p>
                </div>
                <div className="service-item">
                    <h3>Enterprise Architecture</h3>
                    <p>
                        We specialize in designing and implementing effective enterprise architectures to align your IT strategy with your business goals.
                    </p>
                </div>
                <div className="service-item">
                    <h3>Data Architecture & Database Administration</h3>
                    <p>
                        Our data architecture and database administration services guarantee the efficient management and utilization of your data assets.
                    </p>
                </div>
                <div className="service-item">
                    <h3>Design & Development</h3>
                    <p>
                        We excel in providing innovative and customized design and development solutions to enhance your IT systems and applications.
                    </p>
                </div>
                <div className="service-item">
                    <h3>Testing and QA</h3>
                    <p>
                        Our rigorous testing and quality assurance processes ensure the reliability and performance of your IT systems and applications.
                    </p>
                </div>
                <div className="service-item">
                    <h3>Staffing</h3>
                    <p>
                        With our staff augmentation services, you can access a pool of talented professionals who can seamlessly integrate into your team and help you achieve your business goals.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Services;
