import { useState } from 'react';
import './ContactUsForm.css';


const ContactForm = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const contactData = { name, email, message };
    
    const response = await fetch('/api/contactus', {
      method: 'POST',
      body: JSON.stringify(contactData),
      headers: {
        'Content-Type': 'application/json'
      }
    });
    const json = await response.json();

    if (!response.ok) {
      setError(json.error);
    }
    if (response.ok) {
      setError(null);
      setName('');
      setEmail('');
      setMessage('');
      console.log('New contact message added:', json);
    }
  };

  return (
    <form className="create" onSubmit={handleSubmit}>
      <h3>Contact Us</h3>

      <label>Name:</label>
      <input 
        type="text" 
        onChange={(e) => setName(e.target.value)} 
        value={name}
      />

      <label>Email:</label>
      <input 
        type="email" 
        onChange={(e) => setEmail(e.target.value)} 
        value={email}
      />

      <label>Message:</label>
      <textarea 
        onChange={(e) => setMessage(e.target.value)} 
        value={message}
      />

      <button>Send Message</button>
      {error && <div className="error">{error}</div>}
    </form>
  );
};

export default ContactForm;
