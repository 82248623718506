import './Experience.css';

const Experience = () => {
    return (
        <div className="experience">
            <h2>Experience</h2>
            <p>Some of our past experiences:</p>
            <ul className="experience-list">
                <li>Our team of software engineers are successfully maintaining and operating very large-scale claims processing applications, with the flexibility to adopt new reforms, payment models, and regulations over the past 20 years.</li>
                <li>Our subject matter experts and IT technicians have upgraded Healthcare systems to be HIPAA Compliant.</li>
                <li>Our subject matter experts and IT technicians have upgraded Healthcare eligibility systems to be ACA Compliant.</li>
            </ul>
        </div>
    )
}

export default Experience;
