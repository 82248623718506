import './Employment.css';

const Employment = () => {
    return (
        <div className="employment">
            <h2>Employment</h2>
            <p>We offer direct employment, contract to hire and long/short term contract positions.</p>
            <p>Our Benefits include:</p>
            <ul className="benefits-list">
                <li>Competitive Compensation</li>
                <li>401K Plan</li>
                <li>Medical Insurance (Health, Dental & Vision)</li>
                <li>Long Term & Short Term Disability</li>
                <li>Paid Vacation & Sick Time</li>
            </ul>
        </div>
    )
}

export default Employment;
