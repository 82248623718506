import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import './Navbar.css';
import logo from '../assets/Logo.png';  // Adjust the path according to your project structure

const Navbar = () => {
  useEffect(() => {
    // Scroll effect for the navbar
    const handleScroll = () => {
      const header = document.querySelector('header');
      if (window.scrollY > 0) {
        header.classList.add('scrolled');
      } else {
        header.classList.remove('scrolled');
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <header>
      <div className="container">
        <div className="branding">
          <Link to="/" className="logo-link">
            <img src={logo} alt="Binz Solutions Logo" className="logo" />
            <h1>Binz Solutions</h1>
          </Link>
        </div>
        <nav>
          <ul className="nav-links">
            <li><Link to="/">Home</Link></li>
            <li><Link to="/services">Services</Link></li>
            <li><Link to="/experience">Experience</Link></li>
            <li><Link to="/employment">Employment</Link></li>
            <li><Link to="/contactus">Contact Us</Link></li>
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Navbar;
